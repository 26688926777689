<template>
    <b-modal :ref="modalRef" hide-footer hide-header @hidden="destroyModal" centered size="lg">
        <div class="text-center" v-if="utilisateur">
            <b-img :src="pictureSrc(utilisateur, 'utilisateurs')"
                   :alt="textUsername(utilisateur)"
                   class="profile-pic"/>
            <p class="mt-3"><strong>{{ utilisateur.prenom }} {{ utilisateur.nom }}</strong></p>
            <p v-if="utilisateur.pseudo">Aussi appelé <em>{{ utilisateur.pseudo }}</em></p>
            <p v-if="roleInfos">
                Dont le statut est
                <strong class="ml-1">
                    <font-awesome-icon :icon="roleInfos.icon"/>
                    {{ roleInfos.name }}
                </strong>
            </p>
            <p>
                Inscrit depuis le {{ formatDate(utilisateur.dateInscription) }}<br/>
                (Soit une ancienneté de {{ age }})
            </p>
            <p><strong>Associations :</strong> {{ utilisateur.associations.map(({nom}) => nom).join(', ') }}</p>
            <div class="text-center">
                <collected-item :item-type="item.type"
                                :obtention="item.obtention"
                                v-for="item in collectedItems"
                                :key="'collectable_'+item.id"/>
            </div>
            <b-button @click="hideModal" class="mt-3">Fermer</b-button>
        </div>
    </b-modal>
</template>

<script>
    import modalMixin       from '@/mixin/modalMixin';
    import {textUsername}   from '@/util/text';
    import {pictureSrc}     from '@/util/image';
    import user             from '@/util/user';
    import {apiPath}        from '@/util/http';
    import {formatDate}     from '@/util/date';
    import {momentDates}    from '@/util/collectableItem';
    import {naturalSortAsc} from '@/util/sort';

    const CollectedItem = () => import('@/components/collectable_item/CollectedItem');

    export default {
        name: "UserDetailsModal",
        mixins: [modalMixin],
        components: {CollectedItem},
        props: {
            utilisateurId: {
                type: Number,
                required: true
            }
        },
        data: () => ({
            utilisateur: null,
            modalRef: 'userDetail'
        }),
        computed: {
            age() {
                return user.age(this.utilisateur).humanize();
            },
            roleInfos() {
                return user.roleInfos(this.utilisateur);
            },
            collectedItems() {
                return momentDates(this.utilisateur.collectableItems.sort((a, b) => naturalSortAsc(a.type, b.type)));
            }
        },
        methods: {
            textUsername,
            pictureSrc,
            formatDate,
            confirm() {
                this.hideModal();
                this.callback(this.commentaire);
            },
            loadCompleteUser() {
                this.axios.get(apiPath('details_utilisateur', {utilisateur: this.utilisateurId}))
                    .then(response => this.utilisateur = user.momentDates(response.data))
                    .catch(() => this.$toaster.error("Impossible de charger les informations de l'utilisateur"));
            }
        },
        mounted() {
            this.$refs[this.modalRef].show();
            this.loadCompleteUser();
        }
    }
</script>

<style scoped>
    .profile-pic {
        max-width: 128px;
    }
</style>